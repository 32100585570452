import React from "react";

function Testimonial() {
  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
          <h6 className="text-primary text-uppercase">// Testimonial //</h6>
          <h1 className="mb-5">Our Clients Say!</h1>
        </div>
        <div className="testimonial-carousel position-relative row" style={{}}>
          <div className="col-lg-6 testimonial-item text-center">
            <img
              className="bg-light rounded-circle p-2 mx-auto mb-3"
              src="img/testimonial-1.jpg"
              alt=""
              style={{ width: "80px", height: "80px" }}
            />
            <h5 className="mb-0">Ratan Shrestha </h5>
            <p>Profession</p>
            <div className="testimonial-text bg-light text-center p-4">
              <p className="mb-0" style={{textAlign: "justify"}}>
                Car Trade Centre made buying my car so easy! The detailed
                inspection report gave me confidence in my purchase.
              </p>
            </div>
          </div>
          <div className="col-lg-6 testimonial-item text-center">
            <img
              className="bg-light rounded-circle p-2 mx-auto mb-3"
              src="img/testimonial-2.jpg"
              alt=""
              style={{ width: "80px", height: "80px" }}
            />
            <h5 className="mb-0">Dhan Bahadur Karki</h5>
            <p>Profession</p>
            <div className="testimonial-text bg-light text-center p-4">
              <p className="mb-0">
              I sold my car within a week! The process was simple, and the platform was very user-friendly.
              </p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
