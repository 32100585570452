import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Utills/Api";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  background: {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: 'url("https://wallpaperaccess.com/full/840360.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.5,
    zIndex: 1,
  },
  formWrapper: {
    backgroundColor: "white",
    padding: "2rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "400px",
    zIndex: 2,
    position: "relative",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  button: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#013554",
    color: "white",
    fontSize: "1rem",
  },
  title: {
    marginBottom: "1rem",
  },
};

const formWrapperStyles = () => {
  const baseStyles = {
    ...styles.formWrapper,
  };

  if (window.innerWidth <= 768) {
    baseStyles.padding = "1.5rem";
    baseStyles.maxWidth = "300px";
  }

  if (window.innerWidth <= 480) {
    baseStyles.padding = "1rem";
    baseStyles.maxWidth = "90%";
  }

  return baseStyles;
};

const Password = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { phone } = location.state || {};
  const [password, setPassword] = useState('');
  const [con_password, setConPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password.trim() || !con_password.trim()) {
      toast.error("Fill the empty fields");
    }else if(con_password != password){
        alert("Passwords doesnot matched")
    } else {
      try {
        setLoading(true);
        const response = await Api.post(
          "change_password",
          { phone_no : phone,
            password:password
           }
        );
        setLoading(false);
        console.log("Password Changed successful:", response.data);
        navigate("/login", { state: { phone: phone } });
        setConPassword(" ")
        setPassword(" ")
      } catch (error) {
        setLoading(false);
        console.error("otp error:", error);
        toast.error("failed. Please try again.");
      }
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.background}></div>
      <div style={formWrapperStyles()}>
        <h2 style={styles.title}>Change Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            style={styles.input}
            type="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Confirm Password"
            required
            value={con_password}
            onChange={(e) => setConPassword(e.target.value)}
          />
         
          <button style={styles.button} type="submit">
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Password;
