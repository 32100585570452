import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Api from "../Utills/Api";

function Interested() {
  const navigate = useNavigate();

 

  const userId = localStorage.getItem('user_id');
  const [products, setProducts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);

  const getProducts = async () => {
    try {
      const response = await Api.get(`profile/get_wishlist?user_id=${userId}`);
      console.log("wkdhsyuefghwyuegwyuefgwyuefgyu",response.data);
      setProducts(response.data.get_wishlist_array);
    } catch (error) {
      console.log("Error fetching wishlist:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const ownerButton = () => {
    navigate('/seller');
  };

  const productsToShow = products.slice(0, visibleCount);

  return (
    <div>


    

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
             {productsToShow.map((member) => (
              // <div
              //   className="col-lg-3 col-md-6 wow fadeInUp"
              //   data-wow-delay={member.delay}
              //   key={member.id}
              // >
              //   <div className="team-item">
              //     <div
              //       className="position-relative overflow-hidden"
              //       style={{ height: "300px" }}
              //     >
              //       <img
              //         className="img-fluid w-100 h-100"
              //         src={`https://admin.ctcnepal.com/uploads/car_banner/${member.car_banner}`}
              //         alt=""
              //         style={{ objectFit: "contain" }}
              //       />
              //       <div className="team-overlay position-absolute start-0 top-0 w-100 h-100">
              //         <a
              //           className="btn btn-square mx-1"
              //           href=""
              //           style={{
              //             width: "70px",
              //             height: "70px",
              //             display: "flex",
              //             alignItems: "center",
              //             justifyContent: "center",
              //           }}
              //           onClick={(event) => {
              //             event.preventDefault();
              //             navigate("/productDetails", { state: { sale_id: member.id } });
              //           }}
              //         >
              //           <p style={{ margin: 0 }}>Buy Now</p>
              //         </a>
              //         <a
              //           className="btn btn-square mx-1"
              //           href=""
              //           style={{
              //             width: "70px",
              //             height: "70px",
              //             display: "flex",
              //             alignItems: "center",
              //             justifyContent: "center",
              //           }}
              //           onClick={(event) => ownerButton(event, member.user_id, member.id)}
              //         >
              //           <p style={{ margin: 0 }}>Seller Details</p>
              //         </a>
              //       </div>
              //     </div>
              //     <div className="bg-light text-center p-4">
              //       <h5 className="fw-bold mb-0">
              //         {member.title} {member.model}
              //       </h5>
              //       <h5 className="fw-bold mb-0">रु.{member.sale_price}</h5>
              //       <small>
              //         {member.km_running}km • {member.color} • {member.fuel_type}
              //       </small>
              //     </div>
              //   </div>
              // </div> 
              <div
                className="col-sm-6 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay={member.delay}
                key={member.id}
                style={{height:"300px"}}
              >
                <div className="team-item"
                style={{
                  height:"100%",
                  boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                  borderRadius: "10px"
                }}>
                  <div
                    className="all-car position-relative"
                    style={{height:"60%"}}
                    
                  >
                    <img className="buy-car-image"
                      src={`https://admin.ctcnepal.com/uploads/car_banner/${member.car_banner}`}
                      alt=""
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "100%",
                        padding:"5px",
                        borderRadius:"10px"
                        // transform:"transition 0.5s"
                      }}
                    />
                    <div className="rotateImage team-overlay"
                    style={{height:"100%"}}>
                      <a
                        className="btn btn-square mx-1"
                        href=""
                        style={{
                          width: "70px",
                          height: "70px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform:"transition 0.5s",
                          borderRadius:"7px"
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          console.log("Member Id : ",member.id)
                          navigate(`/productDetails?sale_id=${member.id}`, { state: { sale_id: member.id } });
                        }}
                      >
                        <p style={{ margin: 0 }}>Buy Now</p>
                      </a>
                      <a
                        className="btn btn-square mx-1"
                        href=""
                        style={{
                          width: "70px",
                          height: "70px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius:"7px"
                        }}
                        onClick={(event) => ownerButton(event, member.user_id, member.id)}
                      >
                        <p style={{ margin: 0 }}>Seller Details</p>
                      </a>
                    </div>
                  </div>
                  <div className="bg-light text-center" style={{
                      padding: "13px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      height:"40%"
                    }}>
                    <h5 className="fw-bold"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                      margin: '0 auto',
                  }}>
                      {member.title} {member.model}
                    </h5>
                    <h5 className="fw-normal">रु.{member.sale_price}</h5>
                    <p style={{
                      fontSize: ".7em",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                      margin: '0 auto',}}>
                      {member.km_running}km • {member.color} • {member.fuel_type}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div> 
          {visibleCount < products.length && (
            <div className="text-center mt-4">
              <button 
                className="btn btn-primary" 
                onClick={() => setVisibleCount(visibleCount + 12)}>
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
     
    </div>
  );
}

export default Interested;
