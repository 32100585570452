import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Api from '../Utills/Api';

const Seller = () => {
  const location = useLocation();
  const { user_id } = location.state || {};
  console.log("user_id",user_id);
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    getProfile();
    getProfileImage();
  }, [user_id]);

  const getProfile = async () => {
    try {
      const response = await Api.get(`profile?user_id=${user_id}`);
      setProfile(response.data.get_user_data[0]);
    } catch (error) {
      console.log('Error fetching profile:', error);
    }
  };

  const getProfileImage = async () => {
    try {
      const response = await Api.get(`profile/get_profile_pic?user_id=${user_id}`);
      setProfileImage(response.data.get_profile_pic);
    } catch (error) {
      console.log('Error fetching profileImage:', error);
    }
  };

  const openWhatsApp = () => {
    const phone = profile.phone_no.replace(/\D/g, ''); // Remove any non-digit characters
    const url = `https://wa.me/91${phone}?text=Hello`;
    window.open(url, '_blank');
  };

  const styles = {
    container: {
      flex: 1,
      backgroundColor: 'white',
    },
    top: {
      padding: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    box1: {
      backgroundColor: '#013554',
      padding: 20,
      display:"flex"
    },
    box2: {
      textAlign: 'center',
      marginTop: 20,
    },
    profilePic: {
      width: '10%',
      borderRadius: '50%',
      border: '1px solid #fff',
      alignItems:"center",
    },
    name: {
      color: '#fff',
      fontWeight: 'bold',
    },
    info: {
      marginTop: 20,
      paddingLeft: 20,
      fontSize: '18px',
      fontWeight: 600,
      color: 'black',
    },
    contactContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 20px',
      alignItems: 'center',
    },
    contact: {
      fontSize: '16px',
      fontWeight: 500,
    },
    phoneButton: {
      backgroundColor: '#2196F3',
      padding: '10px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
    },
    mailButton: {
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      cursor: 'pointer',
    },
    whatsappButton: {
      backgroundColor: '#25D366',
      padding: '10px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
    },
    backButton: {
      background: 'none',
      border: 'none',
      color: '#fff',
    },
    buttonText: {
      marginLeft: '10px',
    },
    mailButtonText: {
      marginLeft: '10px',
      color: 'black',
    },
    gmail: {
      width: '20px',
      height: '20px',
    },
    divider: {
      borderWidth: 1,
      borderColor: '#013554',
      borderStyle: 'dashed',
      margin: 20,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.box1}>
        <div style={styles.top}>
          <button onClick={() => navigate(-1)} style={styles.backButton}>
            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
          </button>
        </div>
       <div style={{width:"100%"}}>
       <div style={styles.box2}>
          <img
            src={profileImage}
            alt="Profile"
            style={styles.profilePic}
          />
          <h2 style={styles.name}>{profile.full_name}</h2>
        </div>
       </div>
      </div>

      <h3 style={styles.info}>Contact Details</h3>

      <div style={styles.contactContainer}>
        <span style={styles.contact}>Contact via phone</span>
        <button onClick={() => window.location.href = `tel:${profile.phone_no}`} style={styles.phoneButton}>
          <FontAwesomeIcon icon={faPhone} />
          <span style={styles.buttonText}>Call the Seller</span>
        </button>
      </div>

      <div style={styles.contactContainer}>
        <span style={styles.contact}>Contact via mail</span>
        <button onClick={() => window.location.href = `mailto:${profile.email}`} style={styles.mailButton}>
          <img src="img/gmail.png" alt="Gmail" style={styles.gmail} />
          <span style={styles.mailButtonText}>Mail the Seller</span>
        </button>
      </div>

      <div style={styles.contactContainer}>
        <span style={styles.contact}>Contact via WhatsApp</span>
        <button onClick={openWhatsApp} style={styles.whatsappButton}>
          <FontAwesomeIcon icon={faWhatsapp} />
          <span style={styles.buttonText}>Whatsapp</span>
        </button>
      </div>

      <div style={styles.contactContainer}>
        <p style={styles.contact}>Contact Number</p>
        <p>{profile.phone_no}</p>
      </div>

      <div style={styles.divider} />
    </div>
  );
};



export default Seller;
