import React from "react";

function About() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
            <div
              className="position-relative h-100 wow fadeIn"
              data-wow-delay="0.1s"
            >
              <img
                className="position-absolute img-fluid w-100 h-100"
                src="img/about.jpg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              {/* <div className="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                            <h1 className="display-4 text-white mb-0">15 <span className="fs-4">Years</span></h1>
                            <h4 className="text-white">Experience</h4>
                        </div> */}
            </div>
          </div>
          <div className="col-lg-6">
            <h6 className="text-primary text-uppercase">// About Us //</h6>
            <h1 className="mb-4">
              <span className="text-primary">Car Trade Center</span> Is The Best
              Place To Buy Or Sell Cars
            </h1>
            <p className="mb-4" style={{textAlign: "justify"}}>
              Welcome to Car Trade Centre, where the journey to finding or
              selling your perfect car is reimagined. Whether you're hunting for
              a reliable ride or looking to pass on your cherished vehicle to a
              new owner, our platform is designed with you in mind. We blend
              cutting-edge technology with a user-friendly experience, making
              the process of buying or selling a used car as smooth and
              transparent as possible. At Car Trade Centre, we don't just
              connect buyers and sellers; we build trust and ensure satisfaction
              at every turn. Your car journey starts here, where quality meets
              convenience.
            </p>
            <div className="row g-4 mb-3 pb-3">
              <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                <div className="d-flex">
                  <div
                    className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                    style={{ width: "45px", height: "45px" }}
                  >
                    <span className="fw-bold text-secondary">01</span>
                  </div>
                  <div className="ps-3" style={{textAlign: "justify"}}>
                    <h6>Professional & Expert</h6>
                    <span style={{textAlign: "justify"}}>
                      Its not the new one, its already used by someone therefore
                      must take the opinion of related expert before buying.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                <div className="d-flex">
                  <div
                    className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                    style={{ width: "45px", height: "45px" }}
                  >
                    <span className="fw-bold text-secondary">02</span>
                  </div>
                  <div className="ps-3" style={{textAlign: "justify"}}>
                    <h6>Quality Servicing</h6>
                    <span style={{textAlign: "justify"}}>
                      Car Trade Centre always recommend for the good service
                      centre rather than others and get the service with
                      professional mechanic on time to time. We always use the
                      genuine spares and engine oil.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <a href="" className="btn btn-primary py-3 px-5">
              Read More<i className="fa fa-arrow-right ms-3"></i>
            </a> */}
          </div>

         
        </div>
      </div>
    </div>
  );
}

export default About;
