import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import { Carousel } from "react-responsive-carousel";
import Select from "react-select";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Api from "../Utills/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const repairOptions = [
  { value: "00k - 25k", label: "00k - 25k" },
  { value: "25k-50k", label: "25k-50k" },
  { value: "50k-75k", label: "50k-75k" },
  { value: "75k-1L", label: "75k-1L" },
  { value: ">1L", label: ">1L" },
];
const type = [
  { label: "Bank", value: "Bank" },
  { label: "Cash", value: "Cash" },
];
const fuel = [
  { label: "Petrol", value: "Petrol" },
  { label: "Diesel", value: "Diesel" },
  { label: "Electric", value: "Electric" },
  { label: "Hybrid", value: "Hybrid" },
];
const auction = [
  { label: "No", value: "No" },
  { label: "Private", value: "Private" },
  { label: "Government", value: "Government" },
];
const accident = [
  { label: "Minor", value: "Minor" },
  { label: "Medium", value: "Medium" },
  { label: "Big", value: "Big" },
  { label: "None", value: "None" },
];
const transfer = [
  { label: "Instant", value: "Instant" },
  { label: "Within 15 Days", value: "Within 15 Days" },
  { label: "Within 1 month", value: "Within 1 month" },
];

function Sell() {
  const location = useLocation();
  const { plan, price } = location.state || {};
  // console.log(plan, price);
  const [carModel, setCarModel] = useState("");
  const [cc, setCC] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [purchaseYear, setPurchaseYear] = useState("");
  const [kmDriven, setKmDriven] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [carColor, setCarColor] = useState("");
  const [brands, setBrands] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [selectedMaintain, setSelectedMaintain] = useState(null);
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [selectedExpect, setSelectedExpect] = useState(null);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [selectedTrans, setSelectedTrans] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [preview, setPreview] = useState(null);
  const [selectedOwnerType, setSelectedOwnerType] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectOwnerType = (option) => {
    setSelectedOwnerType(option);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setThumbnail(file);
    }
  };

  // console.log("weksftsyufhsufysuefgh=>>>>>>>>>>>>",thumbnail);
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log("files", files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    console.log("urls", imageUrls);

    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    console.log("upfiles>", uploadedFiles);
    setUploadedImages((prevImages) => [...prevImages, ...imageUrls]);
    console.log("wefsrf", uploadedImages);
  };

  const handleRepairChange = (selectedOption) => {
    setSelectedRepair(selectedOption);
  };

  const handleSelectInsurance = (option) => {
    setSelectedInsurance(option);
  };

  const handleType = (option) => {
    setSelectedType(option);
  };
  const handleFuel = (option) => {
    setSelectedFuel(option);
  };
  const handleAuction = (value) => {
    setSelectedAuction(value);
  };
  const handleMaintain = (option) => {
    setSelectedMaintain(option);
  };
  const handleAccident = (option) => {
    setSelectedAccident(option);
    console.log("Selected accident:", option.value);
  };
  const handleExcept = (option) => {
    setSelectedExpect(option);
  };
  const handleTransfer = (option) => {
    setSelectedTransfer(option);
  };
  const handleTrans = (option) => {
    setSelectedTrans(option);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getBrands = async () => {
    try {
      const response = await Api.get(`brand`);
      // console.log(response.data.get_brand_details);
      setBrands(response.data.get_brand_details);
    } catch (error) {
      console.log("Error fetching brands:", error);
    }
  };
  useEffect(() => {
    getBrands();
  }, []);

  const styles = {
    container: {
      maxWidth: isMobile ? "100%" : "1200px",
      margin: "0 auto",
      padding: "20px",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: "20px",
    },
    sliderWrapper: {
      flex: 1,
      marginBottom: "20px",
      marginTop: "25px",
    },
    productInfo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "20px",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    productName: {
      fontSize: isMobile ? "1.5rem" : "2rem",
      marginBottom: "10px",
    },
    form: {
      width: "100%",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "5px",
      display: "block",
      width: "100%",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    input2: {
      width: "95%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    buyButton: {
      padding: "10px 20px",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#0073e6",
      border: "none",
      cursor: "pointer",
      alignSelf: "center",
      marginTop: "20px",
      width: isMobile ? "100%" : "auto",
    },
    uploadedImages: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      marginTop: "20px",
    },
    uploadedImage: {
      width: isMobile ? "80px" : "180px",
      height: isMobile ? "80px" : "100px",
      objectFit: "cover",
      borderRadius: "8px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    column2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    t2: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    optionButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
      border: "1px solid grey",
      borderRadius: "5px",
      cursor: "pointer",
    },
    selectedOption: {
      borderColor: "blue",
    },
    optionText: {
      marginLeft: "5px",
      fontSize: "16px",
    },
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: isMobile ? "100%" : "220px",
      padding: "4px",
      bottom: "5px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "95%",
    }),
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    const uid = localStorage.getItem("user_id");
    try {
      if (
        !uid ||
        !selectedAccident ||
        !selectedAuction ||
        !selectedExpect ||
        !selectedFuel ||
        !selectedInsurance ||
        !selectedMaintain ||
        !selectedOwnerType ||
        !selectedRepair ||
        !selectedTransfer ||
        !selectedType ||
        !selectedValue ||
        !carModel ||
        !registrationNo ||
        !purchaseYear ||
        !kmDriven ||
        !sellingPrice ||
        !carColor ||
        !brands ||
        !cc ||
        !selectedTrans || 
        !thumbnail || 
        !uploadedFiles
      ) {
      } else {
        const formData = new FormData();
        formData.append("user_id", uid);
        formData.append("company", selectedValue);
        formData.append("model", carModel);
        formData.append("car_cc", cc);
        formData.append("registration_no", registrationNo);
        formData.append("purchase_year", purchaseYear);
        formData.append("km_running", kmDriven);
        formData.append("sale_price", sellingPrice);
        formData.append("color", carColor);
        formData.append("owners_type", selectedOwnerType);
        formData.append("insurance", selectedInsurance);
        formData.append("purchase_type", selectedType.value);
        formData.append("auction", selectedAuction.value);
        formData.append("prior_maintainance", selectedMaintain);
        formData.append("accident", selectedAccident.value);
        formData.append("estimated_cost_repair", selectedRepair.value);
        formData.append("expectation", selectedExpect);
        formData.append("ownership_time", selectedTransfer.value);
        formData.append("trans_type", selectedTrans);
        formData.append("fuel_type", selectedFuel.value);
        formData.append("plan_type", plan);

        formData.append("car_banner", thumbnail);

        for (let i = 0; i < uploadedFiles.length; i++) {
          formData.append("car_multi_image[]", uploadedFiles[i]);
        }

        console.log("formdata>>>>>>>", formData);
        if (plan === 'Premium'){
          const response = await Api.postFormdata(
            'premium_plan/payment_api',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );
          console.log('Response:', response.data.payment_url);
          navigate('/pay', { state: {  amount: price,
            url: response.data.payment_url, } });
        }else{
        const response = await Api.postFormdata("sale", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        

        console.log("Response:", response.data);
        navigate('/');
      }
    }
    } catch (error) {
      console.error("Error submitting data:", error);
    }finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <div style={styles.container}>
        <div style={styles.sliderWrapper}>
          <Carousel showThumbs={false} infiniteLoop autoPlay>
            <div>
              <img
                src="https://wallpapersmug.com/download/3840x2160/37da88/mazda-off-road-5k.jpg"
                alt="Product 1"
              />
            </div>
            <div>
              <img
                src="https://wallpapersmug.com/download/3840x2160/9fe0a2/bmw-vision-m-next-concept-cars-hybrid-sports-car.jpeg"
                alt="Product 2"
              />
            </div>
            <div>
              <img
                src="https://images.wallpaperscraft.com/image/single/porsche_911_carrera_rsr_porsche_sports_car_139640_3840x2160.jpg"
                alt="Product 3"
              />
            </div>
          </Carousel>
        </div>
        <div style={styles.productInfo}>
          <h1 style={styles.productName}>List Your Product For Selling</h1>
          <form style={styles.form} onSubmit={handleSubmit}>
            <label style={styles.label}>Select Your Car Brand:</label>
            <select
              style={styles.input}
              onChange={(e) => setSelectedValue(e.target.value)}
            >
               <option value="">Select Car Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.title}
                </option>
              ))}
            </select>

            <label style={styles.label}>Enter Car Model/Variant:</label>
            <input
              type="text"
              style={styles.input}
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
            />
            <div style={styles.column}>
              <label style={styles.label}>Enter Engine Power(cc):</label>
              <input
                type="text"
                style={styles.input}
                value={cc}
                onChange={(e) => setCC(e.target.value)}
              />
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Owner Type:</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedOwnerType === "Owner" &&
                        styles.selectedOption),
                    }}
                    onClick={() => handleSelectOwnerType("Owner")}
                  >
                    {selectedOwnerType === "Owner" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Owner</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedOwnerType === "Business" &&
                        styles.selectedOption),
                    }}
                    onClick={() => handleSelectOwnerType("Business")}
                  >
                    {selectedOwnerType === "Business" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Business</span>
                  </div>
                </div>
              </div>
              <div>
                <label style={styles.label}>Color:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={carColor}
                  onChange={(e) => setCarColor(e.target.value)}
                />
              </div>
            </div>

            <label style={styles.label}>Enter Car Registration Number:</label>
            <input
              type="text"
              style={styles.input}
              value={registrationNo}
              onChange={(e) => setRegistrationNo(e.target.value)}
            />

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Model Year:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={purchaseYear}
                  onChange={(e) => setPurchaseYear(e.target.value)}
                />
              </div>
              <div style={styles.column}>
                <label style={styles.label}>KM Driven:</label>
                <input
                  type="text"
                  style={styles.input2}
                  value={kmDriven}
                  onChange={(e) => setKmDriven(e.target.value)}
                />
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Insurance:</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedInsurance === "Yes" && styles.selectedOption),
                    }}
                    onClick={() => handleSelectInsurance("Yes")}
                  >
                    {selectedInsurance === "Yes" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Yes</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedInsurance === "No" && styles.selectedOption),
                    }}
                    onClick={() => handleSelectInsurance("No")}
                  >
                    {selectedInsurance === "No" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>No</span>
                  </div>
                </div>
              </div>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Estimate Repairing Cost:
                </label>
                <Select
                  value={selectedRepair}
                  onChange={handleRepairChange}
                  options={repairOptions}
                  styles={customStyles}
                />
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Bank/Cash:
                </label>
                <Select
                  value={selectedType}
                  onChange={handleType}
                  options={type}
                  styles={customStyles}
                />
              </div>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Fuel Type:
                </label>
                <Select
                  value={selectedFuel}
                  onChange={handleFuel}
                  options={fuel}
                  styles={customStyles}
                />
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Auction Details:
                </label>
                <Select
                  value={selectedAuction}
                  onChange={handleAuction}
                  options={auction}
                  styles={customStyles}
                />
              </div>
              <div style={styles.column}>
                <label style={styles.label}>Engine Maintenance History:</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedMaintain === "Yes" && styles.selectedOption),
                    }}
                    onClick={() => handleMaintain("Yes")}
                  >
                    {selectedMaintain === "Yes" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Yes</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedMaintain === "No" && styles.selectedOption),
                    }}
                    onClick={() => handleMaintain("No")}
                  >
                    {selectedMaintain === "No" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>No</span>
                  </div>
                </div>
              </div>
            </div>

            <label style={styles.label}>Selling Price : (please don't use any special character)</label>
            <input
              type="number"
              style={styles.input}
              placeholder="please enter numeric value only" 
              min="1"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
            />

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Fixed or Negotiable:</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedExpect === "Fixed" && styles.selectedOption),
                    }}
                    onClick={() => handleExcept("Fixed")}
                  >
                    {selectedExpect === "Fixed" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Fixed</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedExpect === "Negotiable" && styles.selectedOption),
                    }}
                    onClick={() => handleExcept("Negotiable")}
                  >
                    {selectedExpect === "Negotiable" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Negotiable</span>
                  </div>
                </div>
              </div>
              <div style={styles.column}>
                <label style={styles.label}>Accidental Condition:</label>
                <Select
                  value={selectedAccident}
                  onChange={handleAccident}
                  options={accident}
                  styles={customStyles}
                />
              </div>
            </div>

         <div style={styles.rows}>
         <div style={styles.column}>
         <label style={styles.label}>Ownership Transfer Time:</label>
            <Select
              value={selectedTransfer}
              onChange={handleTransfer}
              options={transfer}
              styles={{ customStyles, width: "100%" }}
            />
            </div>

            <div style={styles.column}>
              <label style={styles.label}>Transmission type :</label>
              <div style={styles.rows}>
                <div
                  style={{
                    ...styles.optionButton,
                    ...(selectedTrans === "Manual" && styles.selectedOption),
                  }}
                  onClick={() => handleTrans("Manual")}
                >
                  {selectedTrans === "Manual" ? (
                    <FaCheckSquare size={24} color="blue" />
                  ) : (
                    <FaSquare size={24} color="grey" />
                  )}
                  <span style={styles.optionText}>Manual</span>
                </div>
                <div
                  style={{
                    ...styles.optionButton,
                    ...(selectedTrans === "Automatic" && styles.selectedOption),
                  }}
                  onClick={() => handleTrans("Automatic")}
                >
                  {selectedTrans === "Automatic" ? (
                    <FaCheckSquare size={24} color="blue" />
                  ) : (
                    <FaSquare size={24} color="grey" />
                  )}
                  <span style={styles.optionText}>Automatic</span>
                </div>
              </div>
            </div>

         </div>

         <label style={{ ...styles.label }}>Upload Thumbnail Image:</label>
            <input
              type="file"
              onChange={handleImageChange}
              style={styles.input}
            />

            <div>
              {thumbnail && (
                <img
                  src={preview}
                  alt="Thumbnail Preview"
                  style={styles.uploadedImage}
                />
              )}
            </div>
            
            <label style={{ ...styles.label, marginTop: 10 }}>
              Upload Car Image (Multiple):
            </label>
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              style={styles.input}
            />
            <div style={styles.uploadedImages}>
              {uploadedImages.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`Uploaded ${index + 1}`}
                  style={styles.uploadedImage}
                />
              ))}
            </div>
          
            {loading ? (
       <div  style={styles.buyButton}>
       Loading...
     </div>
      ) : (
            <button type="submit" style={styles.buyButton}>
              Save & Next
            </button>
      )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Sell;
