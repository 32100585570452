import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";

function Terms() {
  return (
    <div>
      <NavBar />
      <div style={{ margin: '0 auto', maxWidth: '800px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ fontSize: '2em', marginBottom: '20px' }}>Terms and Conditions</h1>
      <p>Last updated: [Insert Date]</p>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Introduction</h2>
        <p>
          Welcome to Car Trade Center. By using our website, you agree to comply with and be bound by the following terms and conditions.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Use of Our Services</h2>
        <p>
          Car Trade Center offers services for buying and selling second-hand cars. We provide both free and premium features. Users must register an account to use our services.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>User Responsibilities</h2>
        <p>
          Users must provide accurate information when registering and using our services. Misuse of our platform, including fraud or illegal activities, will result in account termination.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Premium Features</h2>
        <p>
          Premium features offer additional benefits and are available for a fee. Details of these features and the associated costs can be found on our pricing page.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Limitation of Liability</h2>
        <p>
          Car Trade Center is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Changes will be posted on this page, and it is your responsibility to review them regularly.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Contact Us</h2>
        <p>
          If you have any questions about these terms, please contact us at support@cartradecenter.com.
        </p>
      </section>
    </div>
      <Footer/>
    </div>
  );
}

export default Terms;
