import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../Utills/Api';

const Premium = () => {
  const navigate = useNavigate();
    
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubscription = (plan) => {
    alert(`You have chosen the ${plan} plan.`);
    navigate('/sell', { state: { plan: plan, price: products } });
  };

  const styles = {
    container: {
      position: 'relative',
      textAlign: 'center',
      padding: '50px',
      backgroundColor: 'black',
      minHeight: '100vh',
      color: 'white', 
    },
    background: {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: 'url("https://wallpapers.com/images/featured/sports-car-background-ybiazay5uj4y5r5p.jpg")',
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      opacity: 0.5,
      zIndex: 1,
    },
    content: {
      position: 'relative',
      zIndex: 2,
    },
    header: {
      fontSize: '2.5rem',
      marginBottom: '20px',
      color:"white"
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      flexDirection: isMobile ? 'column' : 'row', 
    },
    card: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '300px',
      padding: '20px',
      textAlign: 'left',
      marginBottom: isMobile ? '20px' : '0', 
      color: 'black', 
    },
    cardHeader: {
      fontSize: '1.5rem',
      marginBottom: '10px',
    },
    cardDescription: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
    featuresList: {
      listStyleType: 'none',
      padding: 0,
      marginBottom: '20px',
    },
    button: {
      backgroundColor: '#007BFF',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      fontSize: '1rem',
      cursor: 'pointer',
    },
  };

  const [products, setProducts] = useState("");

  useEffect(() => {
    getProducts()
  }, []);
  
    const getProducts = async () => {
      try {

        const response = await Api.get(`premium_plan`);
        // console.log(response.data.get_plan_data[0].amount);
        setProducts(response.data.get_plan_data[0].amount)
       
      } catch (error) {
        console.log('Error fetching brands:', error);
      }
    };


  return (
    <div style={styles.container}>
      <div style={styles.background}></div>
      <div style={styles.content}>
        <h1 style={styles.header}>Choose Your Plan</h1>
        <div style={styles.cardContainer}>
          <div style={styles.card}>
            <h2 style={styles.cardHeader}>Free Plan</h2>
            <p style={styles.cardDescription}> Enjoy basic features with limited access.</p>
            <p style={{...styles.cardDescription, color:"white"}}>.</p>
            <h1 style={{...styles.cardDescription, color:"white"}}>.</h1>
            {/* <ul style={styles.featuresList}>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul> */}
            <button style={styles.button} onClick={() => handleSubscription('Free')}>
              Choose Free
            </button>
          </div>
          <div style={styles.card}>
            <h2 style={styles.cardHeader}>Premium Plan</h2>
            <p style={styles.cardDescription}>Unlock all features and get premium support.</p>
            <p style={styles.cardDescription}>Recomand your car as 1st priority.</p>
            <h1 style={styles.cardDescription}>At Price of रु..{products}</h1>
            {/* <ul style={styles.featuresList}>
              <li>Feature A</li>
              <li>Feature B</li>
              <li>Feature C</li>
            </ul> */}
            <button style={styles.button} onClick={() => handleSubscription('Premium')}>
              Choose Premium
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premium;
