import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../Utills/Api";

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [sellId, setSellId] = useState(new URLSearchParams(location.search).get("sale_id"));
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Update sellId when the URL changes
    setSellId(new URLSearchParams(location.search).get("sale_id"));
  }, [location.search]);

  useEffect(() => {
    if (sellId) {
      fetchProductDetails();
    }
  }, [sellId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await Api.get(`car/car_details?sale_id=${sellId}`);
      const productData = response.data.get_car_details[0];
      setProduct(productData);
      const imageUrls = response.data.car_images.map(
        (img) =>
          `https://admin.ctcnepal.com/uploads/car_multi_image/${img.car_multi_image}`
      );
      setImages(imageUrls);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const userId = localStorage.getItem("user_id");

  const ownerButton = async (event, user_id, sale_id) => {
    event.preventDefault();
    if (userId) {
      try {
        await Api.post(`car/add_wishlist`, {
          user_id: userId,
          sale_id: sale_id,
        });
        navigate("/seller", { state: { user_id: user_id } });
      } catch (error) {
        console.error("Error adding to wishlist:", error);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      <NavBar />
      <div style={{ ...styles.container, flexDirection: isMobile ? "column" : "row" }}>
        <div style={styles.sliderWrapper}>
          <Carousel showThumbs={false} infiniteLoop autoPlay>
            {images.map((src, index) => (
              <div key={index}>
                <img src={src} alt={`Product ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div style={styles.productInfo}>
          <h1 style={styles.productName}>{product.title} {product.model}</h1>
          <p style={styles.productPrice}>रु.{product.sale_price}</p>
          <small>{product.km_running} km • {product.owners_type} • {product.purchase_year}</small>

          <h1 style={styles.descriptionTitle}>Description</h1>
          <p style={styles.productDescription}>{product.description}</p>

          {Object.entries(productDetailsMapping(product)).map(([key, value]) => (
            <div key={key} style={styles.detail}>
              <span style={styles.t2}>{key}</span>
              <span style={styles.t3}>{value}</span>
            </div>
          ))}

          <button style={styles.buyButton} onClick={(event) => ownerButton(event, product.user_id, product.id)}>
            Contact Seller
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const productDetailsMapping = (product) => ({
  "Car Brand": product.company,
  "Car Model No.": product.model,
  "Car Color": product.color,
  "Car Registration No.": product.registration_no,
  "Model Year": product.purchase_year,
  "km Driven": `${product.km_running} km`,
  "Fuel Type": product.fuel_type,
  "Insurance": product.insurance,
  "Car Engine Power": `${product.car_cc}cc`,
  "EMI or Cash": product.purchase_type,
  "Auction": product.auction,
  "Transmission Type": product.trans_type,
  "Engine Maintenance": product.prior_maintainance,
  "Accident": product.accident,
  "Estimate Repairing Cost": product.estimated_cost_repair,
  "Ownership Transfer": product.ownership_time,
});

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    display: "flex",
    gap: "20px",
  },
  sliderWrapper: {
    flex: 1,
    marginBottom: "20px",
    marginTop: "25px",
  },
  productInfo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  productName: {
    fontSize: "2rem",
    marginBottom: "10px",
  },
  descriptionTitle: {
    fontSize: "1.5rem",
    marginBottom: "10px",
    marginTop: "30px",
  },
  productPrice: {
    fontSize: "1.5rem",
    color: "#b12704",
    marginBottom: "5px",
  },
  productDescription: {
    fontSize: "1rem",
    marginBottom: "20px",
  },
  buyButton: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#0073e6",
    border: "none",
    cursor: "pointer",
    alignSelf: "center",
    marginTop: "20px",
  },
  detail: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
  },
  t2: {
    fontSize: "1rem",
    color: "#333",
  },
  t3: {
    fontSize: "1rem",
    color: "#555",
  },
};

export default ProductDetails;
