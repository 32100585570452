import React from 'react'

function Service() {
  return (
    <div> <div className="container-xxl py-5">
    <div className="container">
        <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-flex py-5 px-4">
                    <i className="fa fa-check-circle fa-3x text-primary flex-shrink-0"></i>
                    <div className="ps-4">
                        <h5 className="mb-3">Quality Checking</h5>
                        <p>We check all the cars befor you buy. Quality guarantee is ours.</p>
                        {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="d-flex bg-light py-5 px-4">
                    <i className="fa fa-network-wired fa-3x text-primary flex-shrink-0"></i>
                    <div className="ps-4">
                        <h5 className="mb-3">Big Network</h5>
                        <p>We have great network of users of Buyer or Seller.</p>
                        {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="d-flex py-5 px-4">
                    <i className="bi bi-wallet  fa-3x text-primary flex-shrink-0"></i>
                    <div className="ps-4">
                        <h5 className="mb-3">Value to Money</h5>
                        <p>You will buy cars at best price and you can sell cars at best price.</p>
                        {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></div>
  )
}

export default Service