import axios from "axios";

const baseUrl = "https://admin.ctcnepal.com/api/";

const get = async (endPoints) => {

  return axios.get(baseUrl + endPoints, {
    headers: {
      
    },
  });
};
const postFormdata = async (endPoints, formData) => {

  return axios.post(baseUrl + endPoints, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const post = async (endPoints, body) => {

  return axios.post(baseUrl + endPoints, body, {
    headers: {
    },
  });
};

const remove = async (endPoints) => {

  return axios.delete(baseUrl + endPoints, {
    headers: {
      
    },
  });
};

export default { get, post, postFormdata, remove };
