import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Page/Home";
import Buy from "./Page/NavComponents/Buy";
import Sell from "./Page/NavComponents/Sell";
import AboutCompany from "./Page/NavComponents/AboutCompany";
import Contact from "./Page/NavComponents/Contact";
import ProductDetails from "./Page/Buying/ProductDetails";
import Login from "./Page/Auth/Login";
import Signup from "./Page/Auth/Signup";
import Terms from "./Page/Footer/Terms";
import Contacts from "./Page/Footer/Contacts";
import Privacy from "./Page/Footer/Privacy";
import Profile from "./Page/NavComponents/Profile";
import Seller from "./Page/Buying/Seller";
import Premium from "./Page/Components/Premium";
import Payment from "./Page/Sell/Payments";
import LoginOtp from "./Page/Auth/LoginOtp";
import Forgotten from "./Page/Auth/Forgotten";
import Password from "./Page/Auth/Password";
import SignupOtp from "./Page/Auth/SignupOtp";
import Result from "./Page/Buying/Result";
import EditSell from "./Page/Profile/EditSell";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/about" element={<AboutCompany />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/productDetails" element={<ProductDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/help" element={<Contacts />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/seller" element={<Seller />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/pay" element={<Payment />} />
        <Route path="/loginOtp" element={<LoginOtp />} />
        <Route path="/signupOtp" element={<SignupOtp />} />
        <Route path="/forgot" element={<Forgotten />} />
        <Route path="/password" element={<Password />} />
        <Route path="/result" element={<Result />} />
        <Route path="/editsell" element={<EditSell />} /> 
       
      </Routes>
    </Router>
  );
}
export default App;
