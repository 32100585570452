// import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// function ExploreServices() {
//   return (
//     <div className="container-xxl service py-5">
//       <div className="container">
//         <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
//           <h6 className="text-primary text-uppercase">// Our Services //</h6>
//           <h1 className="mb-5">Explore Our Services</h1>
//         </div>
//         <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
//           <div className="col-lg-4">
//             <div className="nav w-100 nav-pills me-4" role="tablist">
//               <button
//                 className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active"
//                 data-bs-toggle="pill"
//                 data-bs-target="#tab-pane-1"
//                 type="button"
//                 role="tab"
//                 aria-controls="tab-pane-1"
//                 aria-selected="true"
//               >
//                 <i className="fa fa-car-side fa-2x me-3"></i>
//                 <h4 className="m-0">Diagnostic Test</h4>
//               </button>
//               <button
//                 className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
//                 data-bs-toggle="pill"
//                 data-bs-target="#tab-pane-2"
//                 type="button"
//                 role="tab"
//                 aria-controls="tab-pane-2"
//                 aria-selected="false"
//               >
//                 <i className="fa fa-car fa-2x me-3"></i>
//                 <h4 className="m-0">Engine Servicing</h4>
//               </button>
//               <button
//                 className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
//                 data-bs-toggle="pill"
//                 data-bs-target="#tab-pane-3"
//                 type="button"
//                 role="tab"
//                 aria-controls="tab-pane-3"
//                 aria-selected="false"
//               >
//                 <i className="fa fa-cog fa-2x me-3"></i>
//                 <h4 className="m-0">Tires Replacement</h4>
//               </button>
//               <button
//                 className="nav-link w-100 d-flex align-items-center text-start p-4 mb-0"
//                 data-bs-toggle="pill"
//                 data-bs-target="#tab-pane-4"
//                 type="button"
//                 role="tab"
//                 aria-controls="tab-pane-4"
//                 aria-selected="false"
//               >
//                 <i className="fa fa-oil-can fa-2x me-3"></i>
//                 <h4 className="m-0">Oil Changing</h4>
//               </button>
//             </div>
//           </div>
//           <div className="col-lg-8">
//             <div className="tab-content w-100">
//               <div className="tab-pane fade show active" id="tab-pane-1" role="tabpanel">
//                 <div className="row g-4">
//                   <div className="col-md-6" style={{ minHeight: '350px' }}>
//                     <div className="position-relative h-100">
//                       <img
//                         className="position-absolute img-fluid w-100 h-100"
//                         src="img/service-1.jpg"
//                         style={{ objectFit: 'cover' }}
//                         alt="Diagnostic Test"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <h3 className="mb-3">15 Years Of Experience In Auto Servicing</h3>
//                     <p className="mb-4">
//                       Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita
//                       erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
//                     </p>
//                     <p><i className="fa fa-check text-success me-3"></i>Quality Servicing</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Expert Workers</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Modern Equipment</p>
//                     <a href="#" className="btn btn-primary py-3 px-5 mt-3">
//                       Read More<i className="fa fa-arrow-right ms-3"></i>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="tab-pane fade" id="tab-pane-2" role="tabpanel">
//                 <div className="row g-4">
//                   <div className="col-md-6" style={{ minHeight: '350px' }}>
//                     <div className="position-relative h-100">
//                       <img
//                         className="position-absolute img-fluid w-100 h-100"
//                         src="img/service-2.jpg"
//                         style={{ objectFit: 'cover' }}
//                         alt="Engine Servicing"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <h3 className="mb-3">15 Years Of Experience In Auto Servicing</h3>
//                     <p className="mb-4">
//                       Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita
//                       erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
//                     </p>
//                     <p><i className="fa fa-check text-success me-3"></i>Quality Servicing</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Expert Workers</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Modern Equipment</p>
//                     <a href="#" className="btn btn-primary py-3 px-5 mt-3">
//                       Read More<i className="fa fa-arrow-right ms-3"></i>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="tab-pane fade" id="tab-pane-3" role="tabpanel">
//                 <div className="row g-4">
//                   <div className="col-md-6" style={{ minHeight: '350px' }}>
//                     <div className="position-relative h-100">
//                       <img
//                         className="position-absolute img-fluid w-100 h-100"
//                         src="img/service-3.jpg"
//                         style={{ objectFit: 'cover' }}
//                         alt="Tires Replacement"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <h3 className="mb-3">15 Years Of Experience In Auto Servicing</h3>
//                     <p className="mb-4">
//                       Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita
//                       erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
//                     </p>
//                     <p><i className="fa fa-check text-success me-3"></i>Quality Servicing</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Expert Workers</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Modern Equipment</p>
//                     <a href="#" className="btn btn-primary py-3 px-5 mt-3">
//                       Read More<i className="fa fa-arrow-right ms-3"></i>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="tab-pane fade" id="tab-pane-4" role="tabpanel">
//                 <div className="row g-4">
//                   <div className="col-md-6" style={{ minHeight: '350px' }}>
//                     <div className="position-relative h-100">
//                       <img
//                         className="position-absolute img-fluid w-100 h-100"
//                         src="img/service-4.jpg"
//                         style={{ objectFit: 'cover' }}
//                         alt="Oil Changing"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <h3 className="mb-3">15 Years Of Experience In Auto Servicing</h3>
//                     <p className="mb-4">
//                       Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita
//                       erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
//                     </p>
//                     <p><i className="fa fa-check text-success me-3"></i>Quality Servicing</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Expert Workers</p>
//                     <p><i className="fa fa-check text-success me-3"></i>Modern Equipment</p>
//                     <a href="#" className="btn btn-primary py-3 px-5 mt-3">
//                       Read More<i className="fa fa-arrow-right ms-3"></i>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ExploreServices;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ExploreServices = () => {
  const userId = localStorage.getItem("user_id");

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [hoveredImage, setHoveredImage] = useState(null);

  const handleMouseEnter = (imageId) => {
    setHoveredImage(imageId);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 970);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const sectionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
  };

  const cardStyle = {
    width: "100%",
    marginBottom: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    // border: isMobile ? "0.1px solid black" : "none",
  };

  const textSpan1={
    width: isMobile ? "96%" : "100%",
    justifyContent: "flex-end",
    padding: 5,
    textAlign: "justify",
    margin:"auto",
  }

  const textSpan2={
    width: isMobile ? "96%" : "100%",
    justifyContent: "flex-end",
    padding: 5,
    textAlign: "justify",
    margin:"auto",
  }

  const carImageStyle = (isHovered) => ({
    width: "50%",
    maxWidth: "50%",
    overflow: "hidden",
    transition: "transform 0.3s ease-in-out",
    transform: isHovered ? "scale(1.12)" : "scale(1)",
  });

  const mobileImageTextStyle = {
    width: "100%",
    textAlign: "center",
    maxWidth: "100%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
  };

  const textStyle1 = {
    display: "flex",
    flexDirection: "column",
    width: "47%",
    alignItems: "flex-end",
    paddingRight: "20px",
  };

  const textStyle2 = {
    display: "flex",
    flexDirection: "column",
    width: "47%",
    alignItems: "flex-start",
    paddingLeft: "20px",
  };

  const mobileSectionStyle = {
    ...sectionStyle,
    flexDirection: "column-reverse",
  };

  const handleNavClick = (path) => {
    // navigate(path);
  };

  return (
    <div style={containerStyle}>
      <div
        style={
          isMobile
            ? { ...mobileSectionStyle, ...cardStyle }
            : cardStyle
        }
        // onClick={() => handleNavClick(userId ? "/buy" : "/login")}
      >
        <div
          style={isMobile ? mobileImageTextStyle : carImageStyle(hoveredImage === "first")}
          onMouseEnter={() => handleMouseEnter("first")}
          onMouseLeave={handleMouseLeave}
        >
          <img src="/car2.png" alt="Car for sale" style={imageStyle} />
        </div>
        <div style={isMobile ? mobileImageTextStyle : textStyle1}>
          <h1>Buy a Car</h1>
          <p style={{ fontWeight: "bold" }}>Explore Our Extensive Inventory</p>
          <p style={textSpan1}>
            <span style={{ fontWeight: "bold" }}>1. Wide Selection:</span> Explore a vast range of used cars from different
            brands, models, and price points. Our comprehensive listings include
            everything from budget-friendly options to premium vehicles,
            ensuring that there's something for everyone. <br />
            <span style={{ fontWeight: "bold" }}>2. Verified Listings:</span> All vehicles listed on our platform are
            genuine. We provide needful detailed information, so that buyer can
            take the quick and right decision right there. And high-quality
            images so you can make an informed decision. <br />
            <span style={{ fontWeight: "bold" }}>3. Easy Search and Compare:</span> Our intuitive search tools allow you to
            filter cars by make, model, year, price, and more with the clear
            picture or you can ask the comprehensive details and more pictures
            with the seller after making contact with the seller.
            <br />
            <span style={{ fontWeight: "bold" }}>4. Free platform:</span> This is completely free platform to sell and buy
            the used car. If you want to use the premium plan, you should pay as
            per company policy and the benefits you will get are: your listed
            vehicle always shown at the top until its sold. <br />
            <span style={{ fontWeight: "bold" }}>5. Financial Transaction:</span> This is up to you, how you want to crack
            the deal and make the payment, this is completely between two
            parties, that is you and seller/buyer. Organization wont be
            responsible for any kind of financial dispute between the parties.
          </p>
        </div>
      </div>
      <div
        style={
          isMobile
            ? { ...mobileSectionStyle, ...cardStyle }
            : cardStyle
        }
        // onClick={() => handleNavClick(userId ? "/premium" : "/login")}
      >
        <div
          style={isMobile ? mobileImageTextStyle : textStyle2}
        >
          <h1>Sell Your Car</h1>
          <p style={{ fontWeight: "bold" }}> Get the Best Value for Your Car</p>
          <p style={textSpan2}>
            <span style={{ fontWeight: "bold" }}>1. Quick and Easy Listings:</span> Selling your car has never been easier.
            With a simple, user-friendly interface, you can list your vehicle in
            just a few minutes. Upload photos, provide details, and set your
            asking price. <br />
            <span style={{ fontWeight: "bold" }}>2. Free Vehicle Valuation:</span> This option will be added
            in near future <br />
            <span style={{ fontWeight: "bold" }}>3. Reach Thousands of Buyers:</span> Your listing will be
            seen by a large, engaged audience. With targeted marketing and
            promotion, we ensure that your car gets the attention it deserves.<br />
            <span style={{ fontWeight: "bold" }}>4. Photos and other details:</span> We request all our user to give the
            right and correct information along with the clear photographs. This
            will help buyer to take the decision and will help to sell your
            vehicle as soon as possible. <br />
            <span style={{ fontWeight: "bold" }}>5. Trade-In Option:</span> Looking to upgrade?
            We offer trade-in options that allow you to put the value of your
            old car toward a new purchase, making the transition smooth and
            hassle-free.
          </p>
        </div>
        <div
          style={isMobile ? mobileImageTextStyle : carImageStyle(hoveredImage === "second")}
          onMouseEnter={() => handleMouseEnter("second")}
          onMouseLeave={handleMouseLeave}
        >
          <img src="/car3.png" alt="Car for sale" style={imageStyle} />
        </div>
      </div>
    </div>
  );
};

export default ExploreServices;
