import React from "react";

function Works() {
  return (
    <div>
      {" "}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="d-flex py-5 px-4">
                <i className="bi bi-box-arrow-in-right fa-3x text-primary flex-shrink-0"></i>
                <div className="ps-4">
                  <h5 className="mb-3">Sign Up</h5>
                  <p>
                    Create an account to start browsing or listing vehicles.{" "}
                  </p>
                  {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="d-flex bg-light py-5 px-4">
                <i className="bi bi-view-list fa-3x text-primary flex-shrink-0"></i>
                <div className="ps-4">
                  <h5 className="mb-3">Browse or List</h5>
                  <p>
                    Use our advanced search tools to find your next car or list
                    your current one for sale.
                  </p>
                  {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="d-flex py-5 px-4">
                <i className="bi bi-eye fa-3x text-primary flex-shrink-0"></i>
                <div className="ps-4">
                  <h5 className="mb-3">Inspect</h5>
                  <p>Get a detailed information with seller.</p>
                  {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="d-flex bg-light py-5 px-4">
                <i className="fa fa-handshake fa-3x text-primary flex-shrink-0"></i>
                <div className="ps-4">
                  <h5 className="mb-3">Negotiate</h5>
                  <p>
                    Communicate with buyers or sellers directly through our
                    platform.
                  </p>
                  {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.9s"
            >
              <div className="d-flex py-5 px-4">
                <i className="bi bi-wallet fa-3x text-primary flex-shrink-0"></i>
                <div className="ps-4">
                  <h5 className="mb-3">Secure Payment</h5>
                  <p>
                    Complete the transaction with our secure payment system.
                  </p>
                  {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="1.1s"
            >
              <div className="d-flex bg-light py-5 px-4">
                <i className="fa fa-car fa-3x text-primary flex-shrink-0"></i>
                <div className="ps-4">
                  <h5 className="mb-3">Drive Away</h5>
                  <p>Enjoy your new car or celebrate a successful sale!</p>
                  {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;
