import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  background: {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: 'url("https://wallpaperaccess.com/full/840360.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.5,
    zIndex: 1,
  },
  formWrapper: {
    backgroundColor: "white",
    padding: "2rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "400px",
    zIndex: 2,
    position: "relative",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "0.5rem",
  },
  button: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#013554",
    color: "white",
    fontSize: "1rem",
  },
  title: {
    marginBottom: "1rem",
  },
};

const formWrapperStyles = () => {
  const baseStyles = {
    ...styles.formWrapper,
  };

  if (window.innerWidth <= 768) {
    baseStyles.padding = "1.5rem";
    baseStyles.maxWidth = "300px";
  }

  if (window.innerWidth <= 480) {
    baseStyles.padding = "1rem";
    baseStyles.maxWidth = "90%";
  }

  return baseStyles;
};

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const uid =localStorage.getItem("user_id")
  console.log(uid);
  
  window.onpopstate = () => {
    navigate("/login");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!email.trim() || !password.trim()) {
      toast.error("Enter all the fields");
    } else {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://admin.ctcnepal.com/api/login",
          { email, password }
        );
        setLoading(false);
        console.log("Signin successful:", response.data);
        localStorage.setItem("user_id", JSON.stringify(response.data.user_id));
        setEmail("");
        setPassword("");
        navigate("/");
        window.location.reload(); // Refresh the home page after navigation
      } catch (error) {
        setLoading(false);
        console.error("Signin error:", error);
        toast.error("Signin failed. Please try again.");
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  

  return (
    <div style={styles.container}>
      <div style={styles.background}></div>
      <div style={formWrapperStyles()}>
        <h2 style={styles.title}>Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            style={styles.input}
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
         <div style={{ position: 'relative' }}>
      <input
        style={styles.input}
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        style={styles.toggleButton}
      >
        {showPassword ? 'Hide' : 'Show'}
      </button>
    </div>

<div style={styles.forgotPassword}>
            <button
              style={{
                color: "red",
                borderWidth: 0,
                backgroundColor: "transparent",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
              onClick={() => navigate("/forgot")}
            >
              Forgotten Password?
            </button>
          </div>
          <button style={styles.button} type="submit">
            {loading ? "Loading..." : "Login"}
          </button>
        </form>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          Don't have an account?{" "}
          <button
            style={{
              color: "red",
              marginLeft: "5px",
              borderWidth: 0,
              backgroundColor: "transparent",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            onClick={() => navigate("/signup")}
          >
            Signup
          </button>
        </p>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default Login;
