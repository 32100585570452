import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Utills/Api";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  background: {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: 'url("https://wallpaperaccess.com/full/840360.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: 0.5,
    zIndex: 1,
  },
  formWrapper: {
    backgroundColor: "white",
    padding: "2rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "400px",
    zIndex: 2,
    position: "relative",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  button: {
    width: "100%",
    padding: "0.8rem",
    margin: "0.5rem 0",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#013554",
    color: "white",
    fontSize: "1rem",
  },
  title: {
    marginBottom: "1rem",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  otpInput: {
    width: "40px",
    padding: "0.8rem",
    border: "1px solid #ddd",
    borderRadius: "5px",
    textAlign: "center",
  },
  resendContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    color: "#013554",
  },
};

const formWrapperStyles = () => {
  const baseStyles = {
    ...styles.formWrapper,
  };

  if (window.innerWidth <= 768) {
    baseStyles.padding = "1.5rem";
    baseStyles.maxWidth = "300px";
  }

  if (window.innerWidth <= 480) {
    baseStyles.padding = "1rem";
    baseStyles.maxWidth = "90%";
  }

  return baseStyles;
};

const ForgottenOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { phone } = location.state || {};

  const [f1, setF1] = useState("");
  const [f2, setF2] = useState("");
  const [f3, setF3] = useState("");
  const [f4, setF4] = useState("");
  const [f5, setF5] = useState("");
  const [f6, setF6] = useState("");
  const [count, setCount] = useState(60);
  const [loading, setLoading] = useState(false);

  const et1 = useRef(null);
  const et2 = useRef(null);
  const et3 = useRef(null);
  const et4 = useRef(null);
  const et5 = useRef(null);
  const et6 = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count === 0) {
        clearInterval(interval);
      } else {
        setCount((prevCount) => prevCount - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let enteredOTP = f1 + f2 + f3 + f4 + f5 + f6;

    if (enteredOTP.length !== 6) {
      toast.error("Enter all the fields");
      return;
    }

    try {
      setLoading(true);
      const response = await Api.post(
        "forgot_password/verify_otp",
        { phone_no: phone, otp: enteredOTP }
      );
      setLoading(false);
      if(response.data.status === "failed"){
        toast.error("Invalid OTP");
      }else{
        console.log("OTP verified successfully:", response.data);
      toast.success("OTP Verified successfully");
      navigate("/password", { state: { phone } });
      }
      
    } catch (error) {
      setLoading(false);
      console.error("OTP verification error:", error);
      toast.error("Verification failed. Please try again.");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.background}></div>
      <div style={formWrapperStyles()}>
        <h2 style={styles.title}>Verify your OTP</h2>
        <form onSubmit={handleSubmit}>
          <div style={styles.otpContainer}>
            <input
              style={styles.otpInput}
              type="text"
              maxLength={1}
              value={f1}
              ref={et1}
              onChange={(e) => {
                setF1(e.target.value);
                if (e.target.value.length === 1) et2.current.focus();
              }}
            />
            <input
              style={styles.otpInput}
              type="text"
              maxLength={1}
              value={f2}
              ref={et2}
              onChange={(e) => {
                setF2(e.target.value);
                if (e.target.value.length === 1) et3.current.focus();
                else if (e.target.value.length === 0) et1.current.focus();
              }}
            />
            <input
              style={styles.otpInput}
              type="text"
              maxLength={1}
              value={f3}
              ref={et3}
              onChange={(e) => {
                setF3(e.target.value);
                if (e.target.value.length === 1) et4.current.focus();
                else if (e.target.value.length === 0) et2.current.focus();
              }}
            />
            <input
              style={styles.otpInput}
              type="text"
              maxLength={1}
              value={f4}
              ref={et4}
              onChange={(e) => {
                setF4(e.target.value);
                if (e.target.value.length === 1) et5.current.focus();
                else if (e.target.value.length === 0) et3.current.focus();
              }}
            />
            <input
              style={styles.otpInput}
              type="text"
              maxLength={1}
              value={f5}
              ref={et5}
              onChange={(e) => {
                setF5(e.target.value);
                if (e.target.value.length === 1) et6.current.focus();
                else if (e.target.value.length === 0) et4.current.focus();
              }}
            />
            <input
              style={styles.otpInput}
              type="text"
              maxLength={1}
              value={f6}
              ref={et6}
              onChange={(e) => {
                setF6(e.target.value);
                if (e.target.value.length === 0) et5.current.focus();
              }}
            />
          </div>
          <div style={styles.resendContainer}>
            <span
              onClick={() => count === 0 && setCount(60)}
              style={{ cursor: count === 0 ? "pointer" : "default" }}
            >
              Resend
            </span>
            {count !== 0 && (
              <span style={{ marginLeft: "10px" }}>{count} seconds</span>
            )}
          </div>
          <button style={styles.button} type="submit">
            {loading ? "Loading..." : "Verify"}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ForgottenOtp;
