import React from 'react';
import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

function CustomCarousel() {
  return (
    <div className="container-fluid p-0 mb-5">
      <Carousel id="header-carousel">
        <Carousel.Item>
          <img
            className="w-100"
            src="img/carousel-bg-1.jpg"
            alt="First slide"
          />
          <div className="carousel-caption d-flex align-items-center">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-lg-start">
                <div className="col-10 col-lg-7 text-center text-lg-start">
                  <h6 className="text-white text-uppercase mb-3 animated slideInDown">// Car Trading //</h6>
                  <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown" >Welcome to CTC, <br/>Your Trusted Used Car Marketplace.                  </h1>
                  {/* <a href="#" className="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i className="fa fa-arrow-right ms-3"></i></a> */}
                </div>
                <div className="col-lg-5 d-none d-lg-flex animated zoomIn">
                  <img className="img-fluid" src="img/carousel-1.png" alt="Car"/>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100"
            src="img/carousel-bg-2.jpg"
            alt="Second slide"
          />
          <div className="carousel-caption d-flex align-items-center">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-lg-start">
                <div className="col-10 col-lg-7 text-center text-lg-start">
                  <h6 className="text-white text-uppercase mb-3 animated slideInDown">// Car Selling //</h6>
                  <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">Sell Your Car At Best Price</h1>
                  {/* <a href="#" className="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i className="fa fa-arrow-right ms-3"></i></a> */}
                </div>
                <div className="col-lg-5 d-none d-lg-flex animated zoomIn">
                  <img className="img-fluid" src="img/carousel-2.png" alt="Car"/>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100"
            src="img/carousel-bg-1.jpg"
            alt="Third slide"
          />
          <div className="carousel-caption d-flex align-items-center">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-lg-start">
                <div className="col-10 col-lg-7 text-center text-lg-start">
                  <h6 className="text-white text-uppercase mb-3 animated slideInDown">// Car Buying //</h6>
                  <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">Buy Second Hand Car At New Condition</h1>
                  {/* <a href="#" className="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i className="fa fa-arrow-right ms-3"></i></a> */}
                </div>
                <div className="col-lg-5 d-none d-lg-flex animated zoomIn">
                  <img className="img-fluid" src="img/carousel-1.png" alt="Car"/>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
