import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'

function Privacy() {
  return (
    <div>
        <NavBar/>
        <div style={{ margin: '0 auto', maxWidth: '800px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ fontSize: '2em', marginBottom: '20px' }}>Privacy Policy</h1>
      <p>Last updated:</p>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Introduction</h2>
        <p>
          Car Trade Center values your privacy. This privacy policy explains how we collect, use, and share information about you when you use our website and services.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Information We Collect</h2>
        <p>
          We collect information you provide directly to us, such as when you create an account, post a listing, or contact customer support. This information may include your name, email address, phone number, and payment information.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>How We Use Your Information</h2>
        <p>
          We use the information we collect to provide, maintain, and improve our services, to communicate with you, to process transactions, and for security and fraud prevention.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Sharing Your Information</h2>
        <p>
          We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis. We may also share information if required by law or to protect our rights.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Security</h2>
        <p>
          We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access. However, no security measures are perfect, and we cannot guarantee the security of your information.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Changes to This Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page. You are advised to review this page periodically for any changes.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '1.5em', marginTop: '20px' }}>Contact Us</h2>
        <p>
          If you have any questions about this privacy policy, please contact us at support@cartradecenter.com.
        </p>
      </section>
    </div>
      <Footer/>

    </div>
  )
}

export default Privacy