// import React, { useEffect, useRef } from 'react';
// import { useLocation } from 'react-router-dom';

// const Payment = () => {
//   const location = useLocation();
//   const { amount, url } = location.state; // Assuming the params are passed in the state
//   console.log(url + amount);

//   const iframeRef = useRef(null);

//   useEffect(() => {
//     const checkPaymentStatus = () => {
//       if (iframeRef.current) {
//         try {
//           iframeRef.current.contentWindow.postMessage('payment-success', '*');
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     };

//     const intervalId = setInterval(checkPaymentStatus, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   const handleIframeMessage = (event) => {
//     if (event.origin === window.location.origin) {
//       console.log(event.data); // Logs 'loading', 'payment-success', etc.
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('message', handleIframeMessage);
//     return () => {
//       window.removeEventListener('message', handleIframeMessage);
//     };
//   }, []);

//   return (
//     <div style={{ flex: 1 }}>
//       <iframe
//         ref={iframeRef}
//         src={`${url}${amount}`}
//         style={{ width: '100%', height: '100vh', border: 'none' }}
//         onLoad={() => console.log('Loaded')}
//       />
//     </div>
//   );
// };

// export default Payment;

// import React, { useEffect, useRef } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';

// const Payment = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { amount, url } = location.state;
//   console.log(url + amount);

//   const webViewRef = useRef(null);

//   const handleLoadEnd = (event) => {
//     const src = event.target.src;
//     console.log('Loaded URL:', src);
//     if (src.includes("payment_success")) {
//       navigate("/");
//     }
//   };

//   const handleMessage = (event) => {
//     const message = event.data;
//     console.log("Message from WebView:", message);
//     if (message === 'payment_success') {
//       navigate("/");
//     }
//   };

//   useEffect(() => {
//     const iframe = webViewRef.current;
//     iframe.onload = handleLoadEnd;
//     window.addEventListener('message', handleMessage);

//     return () => {
//       window.removeEventListener('message', handleMessage);
//     };
//   }, []);

//   return (
//     <div style={{ flex: 1 }}>
//       <iframe
//         ref={webViewRef}
//         src={`${url}${amount}`}
//         style={{ width: '100%', height: '100vh' }}
//       />
//     </div>
//   );
// };

// export default Payment;

import React, { useEffect, useRef } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount, url } = location.state;

  const iframeRef = useRef(null);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (iframeRef.current) {
        try {
          iframeRef.current.contentWindow.postMessage('payment-success', '*');
        } catch (error) {
          console.error(error);
        }
      }
    };

    const intervalId = setInterval(checkPaymentStatus, 5000);
    return () => clearInterval(intervalId); 
  }, []);

  const handleLoad = (event) => {
    console.log("uygufygfyug", event.target);
    console.log("event", event);
    
    const link = event.target.src.includes('payment_success');
    const fail = event.target.src.includes('payment_failure');
    console.log("link", link);
    console.log("fail", fail);
    if (link) {
      navigate("/");
    } else if (fail) {
      navigate("/");
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <iframe
        ref={iframeRef}
        src={`${url}${amount}`}
        onLoad={handleLoad}
        style={{ width: '100%', height: '100vh' }}
      />
    </div>
  );
};

export default Payment;

