import React from 'react'
import NavBar from '../Components/NavBar'
import Service from '../Components/Service'
import About from '../Components/About'
import Footer from '../Components/Footer'

function AboutCompany() {
  return (
    <div>
    <NavBar/>

    <div class="container-fluid page-header mb-5 p-0" style={{backgroundImage: `url("img/carousel-bg-1.jpg")`}}>
        <div class="container-fluid page-header-inner py-5">
            <div class="container text-center">
                <h1 class="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center text-uppercase">
                        <li class="breadcrumb-item"><a href="http://localhost:3000/" style={{color:"orange"}}>Home</a></li>
                        {/* <li class="breadcrumb-item"><a href="#" style={{color:"orange"}}>Pages</a></li> */}
                        <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    
    <Service/>
    <About/>
    <div className="col-lg-12 container-xxl py-5">
            <h1 className="mb-4">
              <span className="text-primary">Why choose Us?</span>
            </h1>
            <p className="mb-4">
              At Car Trade Centre, we believe that buying and selling used cars
              should be as easy, transparent, and stress-free as possible.
              Whether you're looking to buy your next car or sell your current
              one, we've got you covered with a platform designed to meet all
              your automotive needs.
            </p>
          </div>
    <Footer/>
</div>
  )
}

export default AboutCompany