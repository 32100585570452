import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Api from "../Utills/Api";

function Result() {
  const [visibleCount, setVisibleCount] = useState(12);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  const headerStyle = {
    backgroundImage: "url(img/carousel-bg-2.jpg)",
  };

  const userId = localStorage.getItem('user_id');
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const response = await Api.get(`car/search_result?company=${id}`);
      console.log("hgfhg",response.data);
      setProducts(response.data.car_details);
    } catch (error) {
      console.log("Error fetching brands:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const productsToShow = products.slice(0, visibleCount);

  const ownerButton = async (event, user_id, sale_id) => {
    event.preventDefault();
    console.log("seller",user_id);
    console.log(userId);
    if (userId) {
      try {
        await Api.post(`car/add_wishlist`, {
          user_id: userId,
          sale_id: sale_id,
        });
        navigate('/seller', { state: { user_id: user_id } });
      } catch (error) {
        console.log("Error adding to wishlist:", error);
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <div>
      <NavBar />

     
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {productsToShow.map((member) => (
              <div
                className="col-sm-6 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay={member.delay}
                key={member.id}
                style={{ height: "300px" }}
              >
                <div className="team-item team-item1" style={{
                  boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                  borderRadius: "10px",
                  height:"100%"
                }}>
                  <div
                    className="all-car position-relative"
                    style={{ height: "60%" }}
                  >
                    <img
                      className="img-fluid w-100 h-100"
                      src={`https://admin.ctcnepal.com/uploads/car_banner/${member.car_banner}`}
                      alt=""
                      style={{ width: "100%",
                        objectFit: "cover",
                        height: "100%",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        transform:"transition 0.5s" }}
                    />
                    <div className="rotateImage team-overlay">
                      <a
                        className="btn btn-square mx-1"
                        href=""
                        style={{
                          width: "70px",
                          height: "70px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform:"transition 0.5s",
                          borderRadius:"7px"
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(`/productDetails?sale_id=${member.id}`, { state: { sale_id: member.id } });
                        }}
                      >
                        <p style={{ margin: 0 }}>Buy Now</p>
                      </a>
                      <a
                        className="btn btn-square mx-1"
                        href=""
                        style={{
                          width: "70px",
                          height: "70px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius:"7px"
                        }}
                        onClick={(event) => ownerButton(event, member.user_id, member.id)}
                      >
                        <p style={{ margin: 0 }}>Seller Details</p>
                      </a>
                    </div>
                  </div>
                  <div className="bg-light text-center" style={{
                      padding: "13px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      height:"40%"
                    }}>
                    <h5 className="fw-bold"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                      margin: '0 auto',
                  }}>
                      {member.title} {member.model}
                    </h5>
                    <h5 className="fw-normal">रु.{member.sale_price}</h5>
                    <p style={{fontSize: ".7em",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                      margin: '0 auto',
                    }}>
                      {member.km_running}km • {member.color} • {member.fuel_type}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibleCount < products.length && (
            <div className="text-center mt-4">
              <button 
                className="btn btn-primary" 
                onClick={() => setVisibleCount(visibleCount + 12)}>
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Result;
