// MainContent.js

import React from "react";
import MyProfile from "../Profile/MyProfile";
import HistoryPage from "../Profile/History";
import Logout from "../Profile/Logout";
import Interested from "../Profile/Interested";


const MainContent = ({ activeButton }) => {
  const getPageContent = () => {
    switch (activeButton) {
      case 1:
        return <div><MyProfile/></div>;
      case 2:
        return <div><HistoryPage/></div>;
      case 3:
        return <div><Interested/></div>;
      case 4:
        return <div><Logout/></div>;
      default:
        return <div><MyProfile/></div>;
    }
  };

  return <div style={{ flexGrow: 1, padding: "20px" }}>{getPageContent()}</div>;
};

export default MainContent;
