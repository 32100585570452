import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import { Carousel } from "react-responsive-carousel";
import Select from "react-select";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Api from "../Utills/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const repairOptions = [
  { value: "00k - 25k", label: "00k - 25k" },
  { value: "25k-50k", label: "25k-50k" },
  { value: "50k-75k", label: "50k-75k" },
  { value: "75k-1L", label: "75k-1L" },
  { value: ">1L", label: ">1L" },
];
const type = [
  { id: "1", label: "Bank", value: "Bank" },
  { id: "2", label: "Cash", value: "Cash" },
];
const fuel = [
  { id: "1", label: "Petrol", value: "Petrol" },
  { id: "2", label: "Diesel", value: "Diesel" },
  { id: "3", label: "Electric", value: "Electric" },
  { id: "4", label: "Hybrid", value: "Hybrid" },
];
const auction = [
  { id: "1", label: "No", value: "No" },
  { id: "2", label: "Private", value: "Private" },
  { id: "3", label: "Government", value: "Government" },
];
const accident = [
  { id: "1", label: "Minor", value: "Minor" },
  { id: "2", label: "Medium", value: "Medium" },
  { id: "3", label: "Big", value: "Big" },
  { id: "4", label: "None", value: "None" },
];
const transfer = [
  { id: "1", label: "Instant", value: "Instant" },
  { id: "2", label: "Within 15 Days", value: "Within 15 Days" },
  { id: "3", label: "Within 1 month", value: "Within 1 month" },
];

function EditSell() {
  const location = useLocation();
  const { sale_id } = location.state || {};
  console.log(sale_id);

  const [selectedBrand, setSelectedBrand] = useState("");
  const [carModel, setCarModel] = useState("");
  const [cc, setCC] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [purchaseYear, setPurchaseYear] = useState("");
  const [kmDriven, setKmDriven] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [carColor, setCarColor] = useState("");
  const [brands, setBrands] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobile1, setIsMobile1] = useState(window.innerWidth <= 1060);
  const [isMobile2, setIsMobile2] = useState(window.innerWidth <= 550);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);

  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [selectedMaintain, setSelectedMaintain] = useState(null);
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [selectedExpect, setSelectedExpect] = useState(null);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [selectedTrans, setSelectedTrans] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [preview, setPreview] = useState(null);
  const [selectedOwnerType, setSelectedOwnerType] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [row_id, setRowId] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const getBrands = async () => {
    try {
      const response = await Api.get(`brand`);
      console.log("Saifu.........", response);
      // console.log(response.data.get_brand_details);
      setBrands(response.data.get_brand_details);
    } catch (error) {
      console.log("Error fetching brands:", error);
    }
  };


  const getDetails = async () => {
    try {
      const response = await Api.get(`car/car_details?sale_id=${sale_id}`);

      const detailData = response.data.get_car_details[0];
      console.log("skud", detailData);

      setRowId(detailData.id);
      setCarModel(detailData.model);
      setCompanyId(detailData.brand_id);
      setSelectedBrand(detailData.company)
      //   setSelectedValue(detailData.company);
      setCC(detailData.car_cc);
      setSelectedTrans(detailData.trans_type);
      setSelectedOwnerType(detailData.owners_type);
      setCarColor(detailData.color);
      setRegistrationNo(detailData.registration_no);
      setPurchaseYear(detailData.purchase_year);
      setKmDriven(detailData.km_running);
      setSelectedInsurance(detailData.insurance);
      setSelectedRepair(detailData.estimated_cost_repair);
      setSelectedType(detailData.purchase_type);
      setSelectedFuel(detailData.fuel_type);
      setSelectedAuction(detailData.auction);
      setSelectedMaintain(detailData.prior_maintainance);
      setSellingPrice(detailData.sale_price);
      setSelectedExpect(detailData.expectation);
      setSelectedAccident(detailData.accident);
      console.log("Ownership Transfer Time:", detailData.ownership_time);
      setSelectedTransfer(detailData.ownership_time);
      const thumbnailImage = `https://admin.ctcnepal.com/uploads/car_banner/${detailData.car_banner}`;

      setThumbnail(thumbnailImage);

      const addPhotoUrls = response.data.car_images.map(
        (img) =>
          `https://admin.ctcnepal.com/uploads/car_multi_image/${img.car_multi_image}`
      );

      setUploadedImages(addPhotoUrls);
    } catch (error) {
      console.log("Error fetching details car?:", error);
    }
  };

  useEffect(() => {
    getDetails();
    getBrands();
  }, []);

  useEffect(() => {
    if (companyId && brands?.length > 0) {
      const brandSelected = brands.find((item) => {
        return item.id === companyId
      })
      setSelectedValue(brandSelected.id)
    }
  }, [companyId, brands])

  const handleSelectOwnerType = (option) => {
    setSelectedOwnerType(option);
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setPreview(reader.result);
  //     // console.log("image : ",reader.result);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //     setThumbnail(file);
  //   }
  // };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setThumbnail(file);
    }
  };


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log("files", files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    console.log("urls", imageUrls);

    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    console.log("upfiles>", uploadedFiles);
    setUploadedImages((prevImages) => [...prevImages, ...imageUrls]);
    console.log("wefsrf", uploadedImages);
  };

  const handleRepairChange = (event) => {
    setSelectedRepair(event.target.value);
  };

  const handleSelectInsurance = (option) => {
    setSelectedInsurance(option);
  };

  // const handleType = (option) => {
  //   console.log("option : ",option.value);
  //   setSelectedType(option.value);
  // };
  const handleType = (event) => {
    setSelectedType(event.target.value);
  };
  const handleFuel = (event) => {
    setSelectedFuel(event.target.value);
  };
  const handleAuction = (event) => {
    setSelectedAuction(event.target.value);
  };
  const handleMaintain = (option) => {
    setSelectedMaintain(option);
  };
  const handleAccident = (event) => {
    setSelectedAccident(event.target.value);
  };
  const handleExcept = (option) => {
    setSelectedExpect(option);
  };
  const handleTransfer = (event) => {
    setSelectedTransfer(event.target.value);
  };
  const handleTrans = (option) => {
    setSelectedTrans(option);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };


  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile1(window.innerWidth <= 1060);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleResize = () => setIsMobile2(window.innerWidth <= 550);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const styles = {
    container: {
      maxWidth: isMobile ? "100%" : "1200px",
      margin: "0 auto",
      padding: "20px",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: "20px",
    },
    sliderWrapper: {
      flex: 1,
      marginBottom: "20px",
      marginTop: "25px",
    },
    productInfo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "20px",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      borderRadius: "8px",
    },
    productName: {
      fontSize: isMobile ? "1.5rem" : "2rem",
      marginBottom: "10px",
    },
    form: {
      width: "100%",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "5px",
      display: "block",
      width: "100%",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    input2: {
      width:isMobile2?"100%" :"95%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    input1: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    buyButton: {
      padding: "10px 20px",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#0073e6",
      border: "none",
      cursor: "pointer",
      alignSelf: "center",
      marginTop: "20px",
      width: isMobile ? "100%" : "auto",
    },
    uploadedImages: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      marginTop: "20px",
    },
    uploadedImage: {
      width: isMobile ? "80px" : "180px",
      height: isMobile ? "80px" : "100px",
      objectFit: "cover",
      borderRadius: "8px",
    },
    row: {
      display: "flex",
      flexDirection:isMobile2 ? "column" : "row",
      justifyContent: "space-between",
      alignItems:isMobile2 ?"start": "center",
      width: "100%",
      gap: isMobile2 ?"0px":"21px"
    },
    row1: {
      display: "flex",
      flexDirection: isMobile1 ? "column" : "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      gap:isMobile1 ? "0px" : "21px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: isMobile2 ? "100%":"50%",
    },
    column1: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width:isMobile2?"100%":isMobile1?"50%":"",
    },
    column2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    t2: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    optionButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
      border: "1px solid grey",
      borderRadius: "5px",
      cursor: "pointer",
    },
    selectedOption: {
      borderColor: "blue",
    },
    optionText: {
      marginLeft: "5px",
      fontSize: "16px",
    },
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: isMobile ? "100%" : "220px",
      padding: "4px",
      bottom: "5px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "95%",
    }),
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const uid = localStorage.getItem("user_id");
    try {

      const formData = new FormData();
      formData.append("row_id", row_id);
      formData.append("user_id", uid);
      formData.append("company", selectedBrand);
      console.log("selectedBrand", selectedBrand);
      // formData.append("company", selectedValue);
      formData.append("model", carModel);
      formData.append("car_cc", cc);
      formData.append("registration_no", registrationNo);
      formData.append("purchase_year", purchaseYear);
      formData.append("km_running", kmDriven);
      formData.append("sale_price", sellingPrice);
      formData.append("color", carColor);
      formData.append("owners_type", selectedOwnerType);
      formData.append("insurance", selectedInsurance);
      formData.append("purchase_type", selectedType);
      console.log("purchase_type", selectedType);
      formData.append("auction", selectedAuction);
      console.log("auction", selectedAuction.value);
      formData.append("prior_maintainance", selectedMaintain);
      formData.append("accident", selectedAccident);
      formData.append("estimated_cost_repair", selectedRepair);
      formData.append("expectation", selectedExpect);
      formData.append("ownership_time", selectedTransfer);
      formData.append("trans_type", selectedTrans);
      formData.append("fuel_type", selectedFuel);

      if (thumbnail) {
        formData.append("car_banner", thumbnail.name);
      }

      console.log("formdata>>>>>>>", formData.get('company'));

      const formDataObj = {};
      formData.forEach((value, key) => {
        formDataObj[key] = value;
      });

      // Print the entire object
      console.log(formDataObj);


      const response = await Api.postFormdata(
        "profile/update_sale",
        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response:", response.data);
      navigate("/");
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <div style={styles.container}>
        <div style={styles.productInfo}>
          <h1 style={styles.productName}>List Your Product For Selling</h1>
          <form style={styles.form} onSubmit={handleSubmit}>
            <label style={styles.label}>Select Your Car Brand:</label>
            {/* <select
              style={styles.input}
              onChange={(e) => setSelectedValue(e.target.value)}
            > */}
            {/* <option value="">Select Car Brand</option> */}
            {/* {brands.map((brand) => (
                <option key={brand.id} value={brand.title}>
                  {brand.title}
                </option>
              ))}
            </select> */}
            <select
              id="carBrand"
              value={selectedBrand}
              onChange={handleBrandChange}
              style={styles.input}
            >
              <option value="selectedBrand">Select a Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.title}>
                  {brand.title}
                </option>
              ))}
            </select>

            <div style={{ display: "flex", flexDirection:isMobile2 ? "column" : "row" ,width: "100%", gap:isMobile2 ?"0px": "21px" }}>
              <div style={{ width:isMobile2?"100%":"50%" }}>
                <label style={styles.label}>Enter Car Model/Variant:</label>
                <input
                  type="text"
                  style={styles.input}
                  value={carModel}
                  onChange={(e) => setCarModel(e.target.value)}
                />
              </div>
              <div style={styles.column}>
                <label style={styles.label}>Enter Engine Power(cc):</label>
                <input
                  type="text"
                  style={styles.input}
                  value={cc}
                  onChange={(e) => setCC(e.target.value)}
                />
              </div>
            </div>
            <div style={styles.row1}>
              <div style={{
                display: "flex",
                width: isMobile1 ? "100%":"50%",
                alignItems: !isMobile2 ?  "center" : "start",
                justifyContent: "space-between",
                flexDirection: isMobile2 ? "column":"row",
              }}>
                <div style={styles.column1}>
                  <label style={styles.label}>Owner Type:</label>
                  <div style={styles.rows}>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedOwnerType === "Owner" &&
                          styles.selectedOption),
                      }}
                      onClick={() => handleSelectOwnerType("Owner")}
                    >
                      {selectedOwnerType === "Owner" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Owner</span>
                    </div>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedOwnerType === "Business" &&
                          styles.selectedOption),
                      }}
                      onClick={() => handleSelectOwnerType("Business")}
                    >
                      {selectedOwnerType === "Business" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Business</span>
                    </div>
                  </div>
                </div>

                <div style={styles.column1}>
                  <label style={styles.label}>Insurance:</label>
                  <div style={styles.rows}>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedOwnerType === "Owner" &&
                          styles.selectedOption),
                      }}
                      onClick={() => handleSelectOwnerType("Owner")}
                    >
                      {selectedOwnerType === "Owner" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Yes</span>
                    </div>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedOwnerType === "Business" &&
                          styles.selectedOption),
                      }}
                      onClick={() => handleSelectOwnerType("Business")}
                    >
                      {selectedOwnerType === "Business" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>No</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                display: "flex",
                width: isMobile1 ? "100%":"50%",
                alignItems:isMobile2 ?"start": "center",
                justifyContent: "space-between",
                flexDirection:isMobile2 ? "column" : "row"
              }}>
                <div style={styles.column1}>
                  <label style={styles.label}>Engine Maintenance History:</label>
                  <div style={styles.rows}>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedMaintain === "Yes" && styles.selectedOption),
                      }}
                      onClick={() => handleMaintain("Yes")}
                    >
                      {selectedMaintain === "Yes" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Yes</span>
                    </div>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedMaintain === "No" && styles.selectedOption),
                      }}
                      onClick={() => handleMaintain("No")}
                    >
                      {selectedMaintain === "No" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>No</span>
                    </div>
                  </div>
                </div>
                {/* <div style={styles.rows}>
                <label style={styles.label}>Insurance:</label>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedInsurance === "Yes" && styles.selectedOption),
                    }}
                    onClick={() => handleSelectInsurance("Yes")}
                  >
                    {selectedInsurance === "Yes" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Yes</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedInsurance === "No" && styles.selectedOption),
                    }}
                    onClick={() => handleSelectInsurance("No")}
                  >
                    {selectedInsurance === "No" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>No</span>
                  </div>
                </div> */}
                <div style={{width: isMobile2 ? "100%":isMobile1 ? "50%" :"" , display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <label style={styles.label}>Color:</label>
                  <input
                    type="text"
                    style={styles.input2}
                    value={carColor}
                    onChange={(e) => setCarColor(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <label style={styles.label}>Enter Car Registration Number:</label>
            <input
              type="text"
              style={styles.input}
              value={registrationNo}
              onChange={(e) => setRegistrationNo(e.target.value)}
            />

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={styles.label}>Model Year:</label>
                <input
                  type="text"
                  style={styles.input1}
                  value={purchaseYear}
                  onChange={(e) => setPurchaseYear(e.target.value)}
                />
              </div>
              <div style={styles.column}>
                <label style={styles.label}>KM Driven:</label>
                <input
                  type="text"
                  style={styles.input1}
                  value={kmDriven}
                  onChange={(e) => setKmDriven(e.target.value)}
                />
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Estimate Repairing Cost:
                </label>
                {/* <Select
                  value={repairOptions.find(
                    (option) => option.value === selectedRepair
                  )}
                  onChange={handleRepairChange}
                  options={repairOptions}
                  styles={customStyles}
                /> */}
                <select
                  value={selectedRepair}
                  onChange={handleRepairChange}
                  style={styles.input}
                >
                  <option value="selectedBrand">select...</option>
                  {repairOptions.map((brand) => (
                    <option key={brand.id} value={brand.value}>
                      {brand.value}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Bank/Cash:
                </label>
                {/* <Select
                  value={type.find((option) => option.value === selectedType)}
                  onChange={handleType}
                  options={type}
                  styles={customStyles}
                /> */}
                <select
                  value={selectedType}
                  onChange={handleType}
                  style={styles.input}
                >
                  <option value="selectedBrand">select...</option>
                  {type.map((brand) => (
                    <option key={brand.id} value={brand.value}>
                      {brand.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Fuel Type:
                </label>
                {/* <Select
                  value={fuel.find(
                    (option) => option.value === selectedFuel
                  )}
                  onChange={handleFuel}
                  options={fuel}
                  styles={customStyles}
                /> */}
                <select
                  value={selectedFuel}
                  onChange={handleFuel}
                  style={styles.input}
                >
                  <option value="selectedBrand">select...</option>
                  {fuel.map((brand1) => (
                    <option key={brand1.id} value={brand1.value}>
                      {brand1.value}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.column}>
                <label style={{ ...styles.label, marginBottom: "10px" }}>
                  Auction Details:
                </label>
                {/* <Select
                  value={auction.find(
                    (option) => option.value === selectedAuction
                  )}
                  onChange={handleAuction}
                  options={auction}
                  styles={customStyles}
                /> */}
                <select
                  value={selectedAuction}
                  onChange={handleAuction}
                  style={styles.input}
                >
                  <option value="selectedBrand">select...</option>
                  {auction.map((brand) => (
                    <option key={brand.id} value={brand.value}>
                      {brand.value}
                    </option>
                  ))}
                </select>
              </div>

            </div>

            <div style={styles.row}>
              {/* <div style={styles.column}>
                <label style={styles.label}>Fixed or Negotiable:</label>
                <div style={styles.rows}>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedExpect === "Fixed" && styles.selectedOption),
                    }}
                    onClick={() => handleExcept("Fixed")}
                  >
                    {selectedExpect === "Fixed" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Fixed</span>
                  </div>
                  <div
                    style={{
                      ...styles.optionButton,
                      ...(selectedExpect === "Negotiable" &&
                        styles.selectedOption),
                    }}
                    onClick={() => handleExcept("Negotiable")}
                  >
                    {selectedExpect === "Negotiable" ? (
                      <FaCheckSquare size={24} color="blue" />
                    ) : (
                      <FaSquare size={24} color="grey" />
                    )}
                    <span style={styles.optionText}>Negotiable</span>
                  </div>
                </div>
              </div> */}
              <div style={{ display: "flex", width: "100%", gap:isMobile2 ? "0px" : "21px",flexDirection:isMobile2 ? "column" : "row"}}>
                <div style={{ width:isMobile2 ? "100%": "50%" }}>
                  <label style={styles.label}>Selling Price:</label>
                  <input
                    type="text"
                    style={styles.input}
                    value={sellingPrice}
                    onChange={(e) => setSellingPrice(e.target.value)}
                  />
                </div>
                <div style={styles.column}>
                  <label style={styles.label}>Accidental Condition:</label>
                  {/* <Select
                  value={accident.find(
                    (option) => option.value === selectedAccident
                  )}
                  onChange={handleAccident}
                  options={accident}
                  styles={customStyles}
                /> */}
                  <select
                    value={selectedAccident}
                    onChange={handleAccident}
                    style={styles.input}
                  >
                    <option value="selectedBrand">select...</option>
                    {accident.map((brand) => (
                      <option key={brand.id} value={brand.value}>
                        {brand.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="transfer-time" style={styles.row1}>
              <div style={styles.column}>
                <label style={styles.label}>Ownership Transfer Time:</label>
                {/* <Select
                  value={transfer.find(
                    (option) => option.value === selectedTransfer
                  )}
                  onChange={handleTransfer}
                  options={transfer}
                  styles={{ customStyles, width: "100%" }}
                /> */}
                <select
                  value={selectedTransfer}
                  onChange={handleTransfer}
                  style={styles.input}
                >
                  <option value="selectedBrand">select...</option>
                  {transfer.map((brand) => (
                    <option key={brand.id} value={brand.value}>
                      {brand.value}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ display: "flex",flexDirection:isMobile2?"column":"row", justifyContent: "space-between", alignItems:!isMobile2 ?"center":"start", width: isMobile1 ? "100%":"50%", }}>
                <div>
                  <label style={styles.label}>Transmission type :</label>
                  <div style={styles.rows}>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedTrans === "Manual" && styles.selectedOption),
                      }}
                      onClick={() => handleTrans("Manual")}
                    >
                      {selectedTrans === "Manual" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Manual</span>
                    </div>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedTrans === "Automatic" &&
                          styles.selectedOption),
                      }}
                      onClick={() => handleTrans("Automatic")}
                    >
                      {selectedTrans === "Automatic" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Automatic</span>
                    </div>
                  </div>
                </div>
                <div style={styles.column}>
                  <label style={styles.label}>Fixed or Negotiable:</label>
                  <div style={styles.rows}>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedExpect === "Fixed" && styles.selectedOption),
                      }}
                      onClick={() => handleExcept("Fixed")}
                    >
                      {selectedExpect === "Fixed" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Fixed</span>
                    </div>
                    <div
                      style={{
                        ...styles.optionButton,
                        ...(selectedExpect === "Negotiable" &&
                          styles.selectedOption),
                      }}
                      onClick={() => handleExcept("Negotiable")}
                    >
                      {selectedExpect === "Negotiable" ? (
                        <FaCheckSquare size={24} color="blue" />
                      ) : (
                        <FaSquare size={24} color="grey" />
                      )}
                      <span style={styles.optionText}>Negotiable</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <label style={{ ...styles.label }}>Upload Thumbnail Image:</label>
            <input
              type="file"
              onChange={handleImageChange}
              style={styles.input}
            />

            <div>
              {thumbnail && (
                <img
                  src={preview}
                  alt="Thumbnail Preview"
                  style={styles.uploadedImage}
                />
              )}
            </div>

            <label style={{ ...styles.label, marginTop: 10 }}>
              Upload Car Image (Multiple):
            </label>
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              style={styles.input}
            />
            <div style={styles.uploadedImages}>
              {uploadedImages.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`Uploaded ${index + 1}`}
                  style={styles.uploadedImage}
                />
              ))}
            </div>

            {loading ? (
              <div style={styles.buyButton}>Loading...</div>
            ) : (
              <button type="submit" style={styles.buyButton}>
                Save & Next
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditSell;
